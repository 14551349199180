import { handleErrorWithSentry, Replay } from "@sentry/sveltekit";
import * as Sentry from '@sentry/sveltekit';
import {
  PUBLIC_ENVIRONMENT_NAME
} from '$env/static/public';

if (PUBLIC_ENVIRONMENT_NAME !== "local") {
  Sentry.init({
    environment: PUBLIC_ENVIRONMENT_NAME,
    dsn: 'https://3d1c2d4e83434798b84db795dcf1a2e7@o4505239729799168.ingest.sentry.io/4505239732486144',
    tracesSampleRate: 1.0,
    includeLocalVariables: true,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // If you don't want to use Session Replay, just remove the line below:
    integrations: [new Replay()],
  });
}

// If you have a custom error handler, pass it to`handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
